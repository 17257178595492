body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.add-Drug {
  border: 3px solid #000099;
  color: #000099;
  border-style: dashed;
  border-radius: 10px;
  font-size: 11px;
  opacity: 0.7;
  display: inline-block;
  width: 120px;
  cursor: pointer;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 30px;
}
.add-Drug input[type="file"] {
  display: none;
}

.sizer input {
  display: none;
}

.add-mission input[type="file"] {
  display: none;
}

.image-gallery-slide img {
  width: 100%;
  min-height: 350px;
  height: 350px;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
  width: 100%;
  min-height: 100%;
  height: 100%;
}
.PhoneInput input {
  border: 1px solid rgba(41, 45, 50, 0.38);
  background-color: #e2e8f0;
  padding: 7px 7px 7px 15px;
  border-radius: 5px;
}

.react-daterange-picker__inputGroup__input:focus {
  background: red;
}

@import "~react-image-gallery/styles/css/image-gallery.css";
